import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/(svgIcons)/clockWhite.svg");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/(svgIcons)/curvedHomepageUnderline.svg");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/(svgIcons)/rightArrow.svg");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/(svgIcons)/scribble.svg");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/(svgIcons)/star.svg");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/(svgIcons)/uploadWhite.svg");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/(svgIcons)/userWhite.svg");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/app/page.module.css");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/components/FAQ/FAQ.module.css");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/components/FAQ/FAQQuestion.jsx");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/components/HorizontalScroll/HorizontalScroll.jsx");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/releases/20241010164425/src/components/Recommendations/Recommendations.module.css");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/shared/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/headshotclub_frontend/shared/node_modules/next/dist/client/link.js")